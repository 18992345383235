import React from "react";
import CharlesMeta from "components/common/meta/CharlesMeta";
import { graphql } from "gatsby";
import BlogLayout from "components/common/blog/BlogLayout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Blocks from "templates/Blog/Gutenberg/Blocks";

import StructuredPerson from "components/common/meta/StructuredPerson";
import authorMap from 'components/Author/data'

class Page extends React.Component {
  render() {
    const {
      location: { search },
      data: { page },
    } = this.props;

    const params = new URLSearchParams(search);
    const shouldCompare = !!params.get("compare");

    let author
    for (const k in authorMap)
      if (authorMap[k].pageUri == page.uri)
        author = authorMap[k]

    return (
      <BlogLayout>
        <CharlesMeta
          title={page.title}
          description={page.title}
          // image={post.frontmatter.shareImage && post.frontmatter.shareImage.publicURL}
        />
        <Container className="mt-3">
          <Row>
            {!!author && (
              <StructuredPerson person={author} />
            )}
            {shouldCompare && (
              <Col>
                <div dangerouslySetInnerHTML={{ __html: page.content }} />
              </Col>
            )}
            <Col>
              <Blocks context={page} blocks={page.blocks} />
            </Col>
          </Row>
        </Container>
      </BlogLayout>
    );
  }
}

export default Page;

export const pageQuery = graphql`
  query BlogPageById($id: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")
      modified(formatString: "MMMM DD, YYYY")
      link
      slug
      status
      uri

      featuredImage {
        node {
          ...BlogImage
        }
      }
      blocks {
        ...GutenbergBlock
      }
    }
  }
`;
